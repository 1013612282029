/* * {
  zoom: 0%;
} */

/* body {
  zoom: 90%;
} */

.App {
  /* zoom: 50% !important; */
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .thecontainer:nth-child(3n + 1) {
  clear: left;
}


.thecontainer {
  float: left;
  margin: 5px;
} */

.preview-data {
  border-style: none !important;
  font-weight: 500;
  /* font-size: 18px !important; */
  padding: 0 !important;
  width: auto;
  pointer-events: none !important;
  resize: none !important;
}

.preview-data .ant-select-selection--multiple {
  border: unset !important;
}

.preview-data:hover {
  border-style: none !important;
}

.preview-data:focus {
  box-shadow: none !important;
}

.preview-data span {
  border-style: none !important;
}

.preview-data span i {
  display: none !important;
}

.preview-data input {
  border: none !important;
  /* font-weight: 500; */
  font-size: 18px !important;
  padding: 0 !important;
  width: auto;
  pointer-events: none !important;
  resize: none !important;
}

.edit-data {
  width: 100% !important;
  font-weight: 500;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #429c46 !important;
}

.MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgba(110, 245, 0, 0.08) !important;
}

.ant-input {
  background-color: unset !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #429c46 !important;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  background: #429c46 !important;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  background: #429c46 !important;
}

.MuiButton-contained {
  background-color: #429c46 !important;
  color: #fff !important;
}

.ant-input:hover {
  border-color: #429c46 !important;
}

.ant-input:focus {
  border-color: #429c46 !important;
}

.ant-calendar-header a:hover {
  color: #429c46 !important;
}

.ant-calendar-month-panel-month:hover {
  background: #e6ffe8 !important;
}

.thecontainer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.ant-btn-primary {
  background-color: #429c46 !important;
  border-color: #429c46 !important;
}

.MuiTabs-indicator {
  background-color: #429c46 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #429c46 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #429c46 !important;
}

html,
body {
  background-color: #fafafa !important;
  /* background-color: #62626245 !important; */
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #429c472f !important;
}

.ant-table-thead > tr > th {
  background: #429c46 !important;
  color: #fff !important;
}

.ant-pagination-item-active a {
  color: #429c46 !important;
}

.ant-pagination-item-active {
  border-color: #429c46 !important;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #429c46 !important;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #429c46 !important;
}

.ant-switch-checked {
  background-color: #429c46 !important;
}

.ant-input-number:hover {
  border-color: #429c46 !important;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #429c46 !important;
  border-color: #429c46 !important;
}

.ant-table-pagination.ant-pagination {
  margin-right: 24px !important;
}

.ant-select-selection:hover {
  border-color: #429c46 !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #429c46 !important;
}

.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #429c472f !important;
}

.ant-select-dropdown-menu-item-selected {
  background-color: #429c472f !important;
}

.thecontainer .box {
  width: 263px !important;
  /* margin: 20px !important; */
  margin: 0 0 20px 0;

  padding: 10px;
  background: #fff;
  overflow: hidden;
  break-inside: avoid;
  /* border: 1px solid gray; */
  /* box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.06); */
  /* opacity: 0;
    animation: fade 0.5s linear forwards;
    animation-delay: 1s; */
}

@media (max-width: 480px) {
  .thecontainer .box {
    width: unset !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.PrivateTabIndicator-colorPrimary-217 {
  background-color: #438838 !important;
}

.ant-select-selection--multiple .ant-select-selection__rendered {
  min-height: 50px;
}

.ant-select-selection--multiple .ant-select-selection__rendered {
  height: 50px;
}

.PrivateTabIndicator-colorPrimary-260 {
  background-color: #438838 !important;
}
.PrivateTabIndicator-colorSecondary-261 {
  background-color: #f50057;
}

.MuiTab-textColorPrimary.Mui-selected {
  /* color: #fff !important; */
}

.ant-btn {
  border: 2px solid transparent;
}

.MuiIconButton-colorPrimary {
  color: #429c46 !important;
}

.MuiIconButton-colorPrimary:hover {
  background-color: rgba(63, 181, 77, 0.08);
}

.antd-danger-button {
  color: red !important;
  border-color: red !important;
}

.ant-input-search-icon {
  color: #429c46 !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #438838 !important;
  background-color: #429c46;
  border-color: #76eb64aa !important;
}

.ant-btn-default {
  background-color: rgba(141, 141, 141, 0.699) !important;
  color: #fff !important;
}

.ant-btn-default:hover {
  color: #fff !important;
}

.modal-custom-from-brilli {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #f3f3f3 !important;
  /* border-radius: 4px !important; */
}

/* .ant-select-selection--multiple .ant-select-selection__choice div {
  color: #fff !important;
}

.ant-select-selection--multiple .ant-select-selection__choice span {
  color: #fff !important;
} */

.ant-select-selection {
  background-color: unset !important;
}

.modal-custom-from-brilli div {
  padding: 0;
  border-radius: 4px;
}

.makeStyles-paper-198 {
  background: #fff !important;
}

.antd-danger-button:hover,
.antd-danger-button:focus {
  color: rgb(241, 87, 87) !important;
  /* background-color: red; */
  border-color: red !important;
}

.ant-spin-dot-item {
  background-color: #429c46 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff !important;
  background-color: #65bf6a !important;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff !important;
  border-color: red !important;
}

.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #438838 !important;
}

.ant-upload-picture-card-wrapper {
  width: auto !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 180px !important;
  height: 180px !important;
}

.MuiIconButton-colorPrimary {
  /* color: #438838 !important; */
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tabs-forhide .ant-tabs-content {
  display: none !important;
}

.tabs-forhide .ant-tabs-bar {
  width: 100% !important;
}

.ant-tabs-ink-bar {
  display: none !important;
}

.ant-tabs-tab-active {
  color: #fff !important;
  border-radius: 5px !important;
  background-color: #429c46 !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #429c46 !important;
}

.ant-tabs-nav .ant-tabs-tab-active:hover {
  color: #fff !important;
}

.ant-tabs .ant-tabs-right-bar {
  border-left: 3px solid #429c46 !important;
}

.details_node
  .ant-tabs-bar
  .ant-tabs-nav-container
  .ant-tabs-nav-wrap
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  .ant-tabs-ink-bar {
  display: block !important;
  background-color: #429c46 !important;
}

.details_node
  .ant-tabs-bar
  .ant-tabs-nav-container
  .ant-tabs-nav-wrap
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  div
  .ant-tabs-tab-active {
  color: unset !important;
  border-radius: unset !important;
  background-color: unset !important;
}

.details_node
  .ant-tabs-bar
  .ant-tabs-nav-container
  .ant-tabs-nav-wrap
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  .ant-tabs-tab:hover {
  color: unset !important;
}

.details_node
  .ant-tabs-bar
  .ant-tabs-nav-container
  .ant-tabs-nav-wrap
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  .ant-tabs-tab-active:hover {
  color: unset !important;
}

.details_node {
  /* border-left: ; */
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #429c46 !important;
  border-color: #429c46 !important;
}

.ant-radio-button-wrapper:hover {
  color: #429c46 !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #fff !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  outline: 3px solid rgba(63, 181, 77, 0.08);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #429c46 !important;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: #fff !important;
}

.dashboard-select {
  color: #fff !important;
  font-weight: 600 !important;
}

.dashboard-select .ant-select-selection {
  background-color: #429c46 !important;
}

.dashboard-select .ant-select-arrow {
  color: #fff !important;
  font-weight: 600 !important;
}

.dashboard-radio-min .ant-radio-button-wrapper {
  padding: 0 32px !important;
}

.jss1 {
  background: #fff !important;
}

.table-sm {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

.table-sm tr td,
.table-sm tr th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.table-sm tr:nth-child(1) {
  background-color: #dddddd;
}
.table-sm tr th,
.table-sm tr,
.table-sm tr td {
  border: 2px solid black;
}

.custom-multi-email {
  background: unset !important;
  font-size: 18px !important;
  padding: 0.55em 0.65em !important;
}

.custom-multi-email input {
  background: transparent !important;
}

.custom-multi-email span {
  top: 0.55em !important;
  left: 0.65em !important;
  font-size: 18px;
}

.pac-target-input {
  width: 100%;
  touch-action: manipulation;
  box-sizing: border-box;
  margin: 0;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  padding: 0;
  font-variant: "tabular-nums";
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  /* zoom: 109.5%; */
}

.pac-target-input:placeholder-shown {
  text-overflow: ellipsis;
}

.pac-target-input:focus {
  border-color: #429c46;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 255, 159, 0.2);
}

.pac-target-input:hover {
  border-color: #429c46 !important;
}

/* .pac-container .pac-logo */
.pac-container {
  /* zoom: 120%; */
  /* width: 100%; */
  /* top: 0 !important; */
  /* left: 1; */
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #429c46 !important;
  border-color: #429c46 !important;
}

.sidebar-custom .MuiDrawer-paper {
  background: #fff;
  /* background-image: url("https://vue-material-dashboard-laravel.creative-tim.com/img/sidebar-2.jpg"); */
  height: 100%;
  max-height: 100%;
  transition-property: top, bottom, width, background-image;
  transition-duration: 0.2s, 0.2s, 0.35s, 0.3s;
  transition-timing-function: linear, linear, ease, linear;
  width: 265px !important;
  padding: 8px;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  background-size: cover;
  background-position: 50%;
  z-index: 9999;
}
.sidebar-custom .MuiDrawer-paper::after {
  /* background-color: rgba(0, 0, 0, 0.77); */
  /* background-color: hsla(0, 0%, 70.6%, 0.77); */
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.button-list {
  margin: 8px 0 !important;
}

.button-list:hover {
  /* background-color: rgba(255, 255, 255, 0.08) !important; */
  border-radius: 4px;
  /* color: #fff !important; */
}

.button-list.active {
  background-color: #438838 !important;
  border-radius: 4px;

  /* baru  */
  color: #fff !important;
}

/* baru  */
.button-list.active .anticon {
  color: #fff !important;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(73, 73, 73, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(73, 73, 73, 0.3);
  background-color: rgb(114, 111, 111);
}

.custom-disabled .MuiInputBase-root.Mui-disabled {
  color: #000 !important;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 92% !important;
  }
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn-primary.blue {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.newtabs
  .ant-tabs-bar
  .ant-tabs-nav-container
  .ant-tabs-nav-wrap
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  .ant-tabs-ink-bar {
  display: unset !important;
  background-color: #429c46;
}

.newtabs
  .ant-tabs-bar
  .ant-tabs-nav-container
  .ant-tabs-nav-wrap
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  div
  .ant-tabs-tab-active {
  color: #000 !important;
  border-radius: 5px !important;
  background-color: unset !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #429c46 !important;
}

.ant-tabs-nav .ant-tabs-tab-active:hover {
  color: #fff !important;
}

.ant-tabs .ant-tabs-right-bar {
  border-left: 3px solid #429c46 !important;
}

.switch-string {
  background-color: #429c46 !important;
}

.custom-popover .ant-popover-content .ant-popover-arrow {
  display: none !important;
}

.table-invoicesass {
  border: 4px solid black;
}

.table-invoicesass tr,
.table-invoicesass td,
.table-invoicesass th {
  border: 2px solid black;
  text-align: center;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: unset !important;
}
.countdown-custom .ant-statistic-content {
  color: unset !important;
  font-size: unset !important;
}

.countdown-custom {
  display: inline-block;
}

.ant-upload-list-item-card-actions a[title="Download file"] {
  display: none !important;
}

.ant-radio-checked::after {
  border: 1px solid #429c46 !important;
}

.ant-radio-inner::after {
  background-color: #429c46 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #429c46 !important;
}
.edit-to-text .ant-input-group {
  color: #000 !important;
  align-items: center;
  display: flex;
  gap: 4;
  flex-direction: row;
}

.ant-input-group-addon {
  font-size: 20px;
}
.edit-to-text .ant-input-group-addon {
  color: #000 !important;
  border: unset !important;
  background: none !important;
  /* padding: 4px 4px !important; */
  font-size: 20px;
  width: min-content;
  /* position: relative; */
}
.edit-to-text .ant-input[disabled] {
  color: #000 !important;
  border: unset !important;
  background: none !important;
  /* padding: 4px 4px !important; */
  font-size: 20px;
  width: min-content;
  /* position: relative; */
}

.edit-to-text {
  color: #000 !important;
  border: unset !important;
  background: none !important;
  /* padding: 4px 4px !important; */
  font-size: 20px !important;
}

.ant-select-dropdown-menu-item-selected {
  display: none !important;
}
